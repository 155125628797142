<template>
  <component
    :is="componentName"
    :key="column.value"
    :account-id="accountId"
    :extra="extra"
    :column="column"
    :item-id="itemId"
    :icon-props="iconProps"
    :value="value"
    :scope-modes="scopeModes"
  />
</template>

<script>
import Column from '../controllers/column'

export default {
  inheritAttrs: false,
  components: {
    AmtColumn: () => import('./columns/amt-column'),
    AssignedColumn: () => import('./columns/assigned-column'),
    BadgeLinksColumn: () => import('./columns/badge-links-column'),
    BalanceSlashAmountColumn: () => import('./columns/balance-slash-amount-column'),
    BoolColumn: () => import('./columns/bool-column'),
    ButtonColumn: () => import('./columns/button-column'),
    CommentColumn: () => import('./columns/comment-column'),
    CreateUpdateAtColumn: () => import('./columns/create-update-at-column'),
    DateColumn: () => import('./columns/date-column'),
    DocumentRefColumn: () => import('./columns/document-ref-column'),
    DocumentRefListColumn: () => import('./columns/document-ref-list-column'),
    DurationColumn: () => import('./columns/duration-column'),
    EmailColumn: () => import('./columns/email-column'),
    FileColumn: () => import('./columns/file-column'),
    IconColumn: () => import('./columns/icon-column'),
    IdNumberColumn: () => import('./columns/id-number-column'),
    LinkColumn: () => import('./columns/link-column'),
    NumberColumn: () => import('./columns/number-column'),
    PercentColumn: () => import('./columns/percent-column'),
    PhoneColumn: () => import('./columns/phone-column'),
    PickerColumn: () => import('./columns/picker-column'),
    SmartEmailSenderColumn: () => import('./columns/smart-email-sender-column'),
    SmartStatusColumn: () => import('./columns/smart-status-column'),
    TextColumn: () => import('./columns/text-column')
  },
  mounted () {
    this.setElement()
  },
  computed: {
    componentName () {
      return this.column?.type ?? 'text-column'
    }
  },
  methods: {
    setElement () {
      this.listElement = this.$parent.$el
    }
  },
  props: {
    accountId: String,
    column: Column,
    extra: Object,
    iconProps: Object,
    itemId: [String, Number, Array],
    value: [String, Array, Number, Object, Boolean],
    scopeModes: Array
  }
}
</script>
